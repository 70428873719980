import React, { useState, useEffect, useRef } from 'react';
import AudioPlayerEmbed from './components/AudioPlayerEmbed';
import { v4 as uuidv4 } from 'uuid'; 
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [loading, setloading] = useState(false);
  const [jsonResponse, setJsonResponse] = useState(null);
  // const [progressMessage, setProgressMessage] = useState('');
  const [progressMessages, setProgressMessages] = useState([]); // Cambiado a array
  const [currentMessage, setCurrentMessage] = useState('');
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  //const [lastShownMessage, setLastShownMessage] = useState('');
  const lastShownMessageRef = useRef('');



  // Suponiendo que la API devuelve 'history' como un arreglo de objetos con mensajes de progreso
const handleProgressUpdate = (progressData) => {
  if (progressData && progressData.history) {
    // Actualizar los mensajes de progreso basados en el historial recibido
    const newMessages = progressData.history.map(entry => entry.message);
    setProgressMessages(newMessages);
    // Opcionalmente, manejar la duración total si es relevante
    console.log(`Duración total de la tarea: ${progressData.total_duration} segundos`);
  }
};


const writeMessage = () => {
  if (progressMessages[messageIndex]) {
    const currentFullMessage = progressMessages[messageIndex];
    if (charIndex < currentFullMessage.length) {
      // Introduce una variación aleatoria entre 50 ms y 120 ms
      const randomDelay = 20 + Math.random() * 120;

      setTimeout(() => {
        setCurrentMessage((currentMessage) => currentMessage + currentFullMessage[charIndex]);
        setCharIndex(charIndex + 1);
      }, randomDelay); // Usa randomDelay en lugar de un valor fijo
    } else if (messageIndex < progressMessages.length - 1) {
      setTimeout(() => {
        setMessageIndex(messageIndex + 1);
        setCharIndex(0);
        setCurrentMessage(''); // Reinicia el mensaje actual al comenzar uno nuevo
      }, 1000); // Espera antes de pasar al siguiente mensaje
    }
  }
};


  useEffect(() => {
    writeMessage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageIndex, charIndex, progressMessages]);


  const addProgressMessage = (newMessage) => {
    console.log('Nuevo mensaje:', newMessage);
    console.log('Último mensaje mostrado:', lastShownMessageRef.current);
    if (newMessage !== lastShownMessageRef.current) {
      setProgressMessages(oldMessages => [...oldMessages, newMessage]);
      lastShownMessageRef.current = newMessage; // Actualiza la referencia del último mensaje mostrado
    }
  };



  const handleSubmit = async () => {
    try {

      setJsonResponse(null); // Reseteamos el estado de la respuesta

      // cambiamos el estado de loading a true
      setloading(true);

      // generate random number to avoid cache
      const random = Math.random();
      // Generar un ID único de tarea
      const taskId = uuidv4();
      console.log('ID de Tarea:', taskId);

      const checkProgress = setInterval(async () => {
        try {
          const progressResponse = await fetch(`https://resume.inapp.io/task-progress?taskId=${taskId}`);
          const progressData = await progressResponse.json();
          console.log('Progreso:', progressData);
          handleProgressUpdate(progressData);
      
          if (progressData.progress) {
            const newMessage = progressData.progress.details;
            addProgressMessage(newMessage);
          }

          if (progressData.progress.status === 'completado') {
            clearInterval(checkProgress);
            // Aquí puedes actualizar el estado de tu aplicación en base al progreso completado
          }
        } catch (error) {
          console.error('Error al verificar el progreso:', error);
        }
      }, 2500); // Consultar cada segundo

      // Enviar URL y ID de tarea a tu API
      const apiUrl = `https://resume.inapp.io/processurl?url=${encodeURIComponent(url)}&taskId=${taskId}&userId=01234567890&cache=${random}`;
      const response = await fetch(apiUrl);
      const data = await response.json();

      // Usar datos simulados para pruebas
      // const data = { json_url: 'json_656924efb64bb2.35147122' };

      console.log(data);
      // Los datos del api devuelven una url con el json y los valores, recuperamos el json

      if (data && data.task_id) {
        setJsonResponse(data);
        clearInterval(checkProgress);
        addProgressMessage('Resumen generado');
        console.log("Datos recibidos y estado actualizado:", data);
      } else {
        console.error("Respuesta inválida o datos faltantes");
        clearInterval(checkProgress);
        // Puedes establecer un mensaje de error en el estado si lo necesitas
      }
    
      setloading(false);

    } catch (error) {
      console.error("Error al obtener el resumen:", error);
      // Aquí puedes manejar el error como consideres apropiado
    }
  };

  return (
    <div className="bg-white py-16 sm:py-24">
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
        <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
        Unlock Insights in Seconds.
        </h2>
        <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
        Submit your URL for a swift, smart summary
        </p>
        <form className="mx-auto mt-10 mb-10 flex max-w-md gap-x-4">
          <label htmlFor="email-address" className="sr-only">
            Summary URL
          </label>
          <input
            id="url-address"
            name="url"
            type="text"
            autoComplete="email"
            required
            className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
            placeholder="Enter your URL"
            value={url} onChange={(e) => setUrl(e.target.value)}
          />
          <button
            onClick={handleSubmit}
            type="button"
            className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            {!loading && <p>Get Summary</p>  }
            {loading && <div role="status">
              <svg aria-hidden="true" className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span className="sr-only">Loading...</span>
          </div>}
          </button>
        </form>
        <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
        {currentMessage}&nbsp;
        </p>
        {jsonResponse && (
          <AudioPlayerEmbed resumeId={jsonResponse.task_id} userId="01234567890" />
        )}
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
          aria-hidden="true"
        >
          <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
          <defs>
            <radialGradient
              id="759c1415-0410-454c-8f7c-9a820de03641"
              cx={0}
              cy={0}
              r={1}
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(512 512) rotate(90) scale(512)"
            >
              <stop stopColor="#7775D6" />
              <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
  );
}

export default App;
