import React, { useEffect } from 'react';

const AudioPlayerEmbed = ({ resumeId, userId }) => {
  useEffect(() => {
    console.log("resumeId:", resumeId);
    console.log("userId:", userId);


    const containerId = 'resumeCast-container';
    const playerConfig = {
        resumeId: resumeId,
        userId: userId,
        containerId: containerId,
      };

    const script = document.createElement('script');
    script.src = "https://resumely-player.pages.dev/bundle.js";
    script.onload = () => {
            setTimeout(() => {
            window.renderAudioPlayer(playerConfig);
            }, 1000);
    }

    //window.renderAudioPlayer(playerConfig);
    document.body.appendChild(script);

    return () => {
      // Cleanup script and link when the component is unmounted
      document.body.removeChild(script);
    };
  }, [resumeId, userId]);

  return <div id="resumeCast-container"></div>;
};

export default AudioPlayerEmbed;
